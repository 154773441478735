
.accordion__body {
  padding: 0;
  .section__course-text {
    padding: 2.4rem 0;
  }
}
.accordion__header {
  padding: 0 2.4rem 0 0;
  h2 {
    padding: 2.4rem 0 2.4rem 2.4rem;
  }
}
